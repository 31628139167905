import { calCurrentTaxYear } from "../calculateTaxYear";
import {
  BULK_DELETE_GROUPS,
  BULK_REMOVE_STAFF,
  CP_BULK_DELETE,
  CP_BULK_DISABLE_ONLINE_ACCESS,
  CP_BULK_DISABLE_POSTAL_MAILING,
  CP_BULK_DISABLE_STATE_FILING,
  CP_BULK_DOWNLOAD,
  CP_BULK_ENABLE_ONLINE_ACCESS,
  CP_BULK_ENABLE_POSTAL_MAILING,
  CP_BULK_ENABLE_STATE_FILING,
  PAYER_COPY,
  RECIPIENT_COPY,
  STATE_COPY,
} from "../constants";

class ReactSelectOptions {
  static cartPageBulkUpdateOptions(totalcartvals: any): any {
    return [
      {
        label: CP_BULK_DOWNLOAD,
        value: CP_BULK_DOWNLOAD,
      },
      {
        label: CP_BULK_DELETE,
        value: CP_BULK_DELETE,
      },
      {
        label: CP_BULK_ENABLE_POSTAL_MAILING,
        value: CP_BULK_ENABLE_POSTAL_MAILING,
        isDisabled: totalcartvals.totalPM !== 0,
      },
      {
        label: CP_BULK_DISABLE_POSTAL_MAILING,
        value: CP_BULK_DISABLE_POSTAL_MAILING,
        isDisabled: totalcartvals.totalPM === 0,
      },
      {
        label: CP_BULK_ENABLE_STATE_FILING,
        value: CP_BULK_ENABLE_STATE_FILING,
        isDisabled: totalcartvals.totalSF !== 0,
      },
      {
        label: CP_BULK_DISABLE_STATE_FILING,
        value: CP_BULK_DISABLE_STATE_FILING,
        isDisabled: totalcartvals.totalSF === 0,
      },
      {
        label: CP_BULK_ENABLE_ONLINE_ACCESS,
        value: CP_BULK_ENABLE_ONLINE_ACCESS,
        isDisabled: totalcartvals.totalOA !== 0,
      },
      {
        label: CP_BULK_DISABLE_ONLINE_ACCESS,
        value: CP_BULK_DISABLE_ONLINE_ACCESS,
        isDisabled: totalcartvals.totalOA === 0,
      },
    ];
  }

  public static staffRelatedBulkActions(totalStaffIdsSelected: number) {
    return [
      {
        label: "Remove Members",
        value: BULK_REMOVE_STAFF,
        isDisabled: totalStaffIdsSelected === 0,
      },
    ];
  }

  public static groupRelatedBulkActions(totalGroupIdsSelected: number) {
    return [
      {
        label: "Delete Groups",
        value: BULK_DELETE_GROUPS,
        isDisabled: totalGroupIdsSelected === 0,
      },
    ];
  }

  static yearOptions() {
    const options = [];
    for (let i = 0; i < 2; i++) {
      const year = calCurrentTaxYear();
      const option = {
        label: year - i,
        value: year - i,
      };
      options.push(option);
    }
    return options;
  }

  static payerOptions(payerDataArray: any[]) {
    const payerSelectOptions = payerDataArray.map((payerData: any) => {
      return {
        label: payerData.payer_name,
        value: payerData,
      };
    });
    return payerSelectOptions;
  }

  static downloadOptions() {
    return [
      {
        label: "State copy",
        value: STATE_COPY,
      },
      {
        label: "Payer copy",
        value: PAYER_COPY,
      },
      {
        label: "Recipient copy",
        value: RECIPIENT_COPY,
      },
    ];
  }

  static formDownloadOptions(selectedFormsLen: number): any {
    return [
      {
        label: "Bulk Download State Copy",
        value: STATE_COPY,
        isDisabled: selectedFormsLen <= 0, // TODO: Uncomment when state copy download API is finished
      },
      {
        label: "Bulk Donwload Payer copy",
        value: PAYER_COPY,
        isDisabled: selectedFormsLen <= 0,
      },
      {
        label: "Bulk Download Recipient copy",
        value: RECIPIENT_COPY,
        isDisabled: selectedFormsLen <= 0,
      },
    ];
  }
}

export default ReactSelectOptions;
