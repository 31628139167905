import React from "react";
import { form1099 } from "../lib/languagePacks/en-us";
import ManualEntryCard from "./ManualEntryCard";
import FileUploadCard from "./FileUploadCard";
import Select from "react-select";
import ReactSelectOptions from "../lib/utils/ReactSelectOptions";
import SelectStyle from "../lib/ReactSelectTheme/reactSelectStyle";
import { useSelector } from "react-redux";
import { useReactSelectTaxYear } from "../lib/customHooks";

export default function CreateFormComponent({ formType }: any) {
  const style = useSelector((state: any) => state.themeChange);
  const [year, handleYearChange] = useReactSelectTaxYear();

  return (
    <div className="create-form-info-container">
      <div className="form-create-heading-wrapper">
        <h3 className="text-lg font-bold">{form1099.createForm.heading}:</h3>
      </div>

      <div className="px-4">
        <div className="flex justify-center">
          <div className="w-[30%] sm:w-[15%] py-4">
            <Select
              value={year}
              options={ReactSelectOptions.yearOptions()}
              theme={new SelectStyle(style).customTheme}
              styles={new SelectStyle(style).customStyle}
              onChange={handleYearChange}
              isSearchable={false}
              // isDisabled // Remove when dynamic year filing is supported
            />
          </div>
        </div>
        <div className="py-4 text-center text-taxeve-primary-blue">
          <h1>You are filing for year {year.value}</h1>
          <p className="text-black">
            ({form1099.filingDueDateText}: {formType.filingDueDate}, {new Date().getFullYear()})
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-6 sm:gap-0 justify-evenly py-8 px-4 sm:px-0">
        <ManualEntryCard formType={formType} filingYear={year.value} />
        <FileUploadCard formType={formType} filingYear={year.value} />
      </div>
    </div>
  );
}
