import React from "react";
import { IoWarning } from "react-icons/io5";
import Select from "react-select";
import ReactSelectOptions from "../../../../lib/utils/ReactSelectOptions";
import SelectStyle from "../../../../lib/ReactSelectTheme/reactSelectStyle";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CORRECTION } from "../../../../lib/routes";
import { useReactSelectTaxYear } from "../../../../lib/customHooks";

export default function CorrectionFilingModal() {
  const style = useSelector((state: any) => state.themeChange);
  const [year, handleYearChange] = useReactSelectTaxYear();

  return (
    <div className="w-[50rem] rounded-xl overflow-hidden">
      <section className="px-4 py-4 bg-taxeve-primary-yellow">
        <h2>Form 1099-Corrections</h2>
      </section>
      <section className="modal-body bg-white px-4 py-8 flex flex-col items-center gap-4">
        <h3 className="flex items-center justify-center gap-2 text-slate-600">
          <IoWarning className="text-xl text-yellow-500" />
          You can only file a 1099 correction after the IRS has accepted your original 1099.
        </h3>
        <div className="w-[20%]">
          <Select
            value={year}
            options={ReactSelectOptions.yearOptions()}
            theme={new SelectStyle(style).customTheme}
            styles={new SelectStyle(style).customStyle}
            onChange={handleYearChange}
            isSearchable={false}
          />
        </div>
        <div className="py-4 text-center text-taxeve-primary-violet">
          <h1>You are filing for year {year.value} </h1>
        </div>
        <Link to={`${CORRECTION}/${year.value}`} className="primary-button py-4 px-6">
          File Form 1099-Correction
        </Link>
      </section>
    </div>
  );
}
